.calculator {

}

.calculator__ranks {
    display: flex;
    justify-content: space-between;
}

.calculator__rankname {
    font-weight: 300;
    color: #fff;
}

.calculator__col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.calculator__ratingname {
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    margin-bottom: 32px;
}

.calculator__image {
    width: 200px;
    margin-bottom: 20px;

}

.calculator__ranks {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 80%;
}

.calculator__discount_line {
    width: 60%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 20px;
}



.calculator__range {
    margin-bottom: 38px;
}

.calculator__label {
    font-weight: 300;
    color: #fff;
    margin-left: 8px;
}

.calculator__checkbox {
    display: flex;
    align-items: center;
}

.calculator__price {
    font-weight: 300;
    color: #fff;
    display: flex;
    justify-content: center;
}

.calculator__price span {
    font-weight: 700;
    color: #fff607;
    margin-left: 4px;
}

.calculator__count_input {
    background-color: #1a1e28;
    width: 180px;
    height: 40px;
    color: #fff;
    border: 1px solid #2d3343;
    border-radius: 4px;
    padding-left: 10px;
}

.calculator__col_f {
    display: flex;
}

.calculator__col_f img {
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.calculator__col_f input {
}

.calculator__col_f h3 {
    font-weight: 300;
    color: #fff;
    text-align: center;
    font-size: 13px !important;
    margin-bottom: 30px !important;
}


@media screen and (max-width: 938px) {
    .calculator__discount_line {
        width: 100%;
    }

    .calculator__ranks {
        width: 100%;
    }
}

@media screen and (max-width: 840px) {

    .calculator__col_f {
        flex-direction: column;
        align-items: center;
    }

    .calculator__col_f input  {
        width: 100px;
    }

    .calculator__col_f img{
        width: 60px;
    }
}

@media screen and (max-width: 756px) {
    .calculator__image {
        width: 100px;
    }

    .calculator__ranks {
        width: 100%;
    }

    .calculator__ratingname {
        font-size: 16px;
        margin-bottom: 10px;
    }
}